import React, {useState, useEffect} from 'react';
import Loading from '../../img/loading.svg';

export default function Page(props) {

  const [page, setPage] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [dfs, setDfs] = useState("./dfs/Estados Financieros Diciembre 2018.pdf");
  const [location, setLocation] = useState('');

  useEffect(() => {
    console.log('file', props.file);
    if(props.file !== undefined) {
      setPage(props.file);
      setPageLoaded(false);
      loadImage(props.file);

      if(props.file === "latam-168.png") {
        console.log("excelsior!");
      }
    }
  },[props.file]);

  useEffect(() => {

    setLocation(props.location);
    console.log('must match location', location);
    if(props.location === "en") {
      setDfs("./dfs/Financial Statements - LATAM 2018.pdf")
    }
  }, [props.location]);


  const loadImage = (img) => {
    console.log('props.location', props.location);
    fetch(`./pages/${props.location}/${img}`)
      .then(response => response.blob())
      .then((result) => {
        console.log(result);
        const url = URL.createObjectURL(result);
        setImage(url);
        console.log(image);
        setPageLoaded(true);
      }, (error) => {
        console.log('deu erro na imagem');
        console.log(error);
      })
  };

  const handleClick = () => {
    if(props.currentPage === 0) {
      props.navigate("1");
    }
  }

  const renderPage = (file) =>{
    if(file) {
      return(
        <>
          {props.file === "latam-168.png" ? (
            <div className="dfs" >
              <iframe src={dfs} frameBorder="0" title="Dfs"></iframe>
            </div>
          ):''}

          {pageLoaded ? (<img  src={image} alt=""/>) : (<div className="loading"><img  src={Loading} alt=""/></div>)}

        </>
      )
    } else {
      return (
        <div>File is null</div>
      )
    }
  };

  return(
    <div className="page">
      {renderPage(page)}
    </div>
  )
}
