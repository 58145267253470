import React from 'react';
// import SizeDetector from '@atlaskit/size-detector';


class Desktop extends React.Component {

  constructor(props) {
    super(props);
    this.state = {width:0, height: 0};
  }

  setResize = ({width, height}) => {
    this.setState({width: width, height: height});
  }

  displayResults = () => {
    return (
      <div>
        <h1>Oi Mundo!</h1>
      </div>
    )
  };


  render(){
    return(
      <div id="desktop" style={{height:'100vh'}}>
          <div className="ar-desktop">
            <div>{this.props.children}</div>
          </div>
      </div>
    )
  }
}

export default Desktop;
