import React, {useState, useEffect} from 'react';
// import MenuCloseBtn from './MenuCloseBtn';
import uuidv1 from 'uuid/v1';

import MenuClose from '../../img/but-menu-close.svg';
import ButMenu from '../../img/but-menu.svg';
import LogoMobile from '../../img/mobile-marca.svg';
import LogoMenuMobile from '../../img/mobile-logo-menu.svg';

export function Menu (props) {


  const [menu, setMenu] = useState([]);
  const [itemActive, setItemActive] = useState(null);
  const [firstClick, setFirstClick] = useState(false);

  useEffect(()=> {
    if(props.structure !== undefined && props.structure.length > 0) {
      // console.log('structure from effect', props.structure);
      setMenu(props.structure);
    } else {
      // console.log('no structure yet')
    }
  },[props]);

  const generateMenu = (branch) => {
    return Array.isArray(branch) ?
      (<ul>{branch.map((item) => makeItem(item))}</ul>) :
      console.log('not array');
  };

  // window.location.hash = `/${props.location}/${props.device}/${branch.id + '-i0'}`

  const checkLink = (branch, event) => {

    event.stopPropagation();

    console.log('mob branch', branch);

    if(itemActive === branch.id) {
      console.log('not first click');
    }


    if(branch.nolink === true) {
      return;
    }

    if(branch.titleType === 'upper' && itemActive !== branch.id) {
      setItemActive(branch.id);
      console.log('upper open menu');
    } else {
      console.log('redirecting');

    }

    window.scrollTo(0,0);
    //window.location.hash = `${props.location}/m/${branch.id + '-i0'}`
    window.location.hash = `${props.location}/m/${branch.id}`

  };

  const checkActive = branch => {
    if(branch.id === itemActive) {
      console.log('its active');
      return "active"
    }

    return "";
  };

  const makeItem = (branch) => {

    console.log('mob branch', branch);

    return(
      <li key={uuidv1()} className={`${branch.titleType} ${checkActive(branch)}`} >
        <span onClick={(event) => checkLink(branch, event)}>
          {branch.title[props.location]}
        </span>
        {branch.leaf? '': generateMenu(branch.children)}
      </li>
    );
  };

  const openLink = () => {
    console.log('open menu');
    if(props.location === "es") {
      window.open(
        './dfs/LATAM_MemoriaAnual2018_ESP.pdf',
        '_blank'
      );
    } else if (props.location === "en") {
      window.open(
        './dfs/LATAM_IntegratedReport_2018_EN.pdf',
        '_blank'
      );
    } else {
      window.open(
        './dfs/LATAM_RelatorioIntegrado2018_PT.pdf',
        '_blank'
      );
    }
  };

  const changeLocation = (newLocation) => {

    const actualPage = /\/(?:.(?!\/))+$/;
    const address = window.location.hash;
    const pageUrl = address.match(actualPage)[0];
    console.log('pageURL', pageUrl);

    const mobVal = pageUrl === "/m" ? '':'/m';

    window.location.hash = `/${newLocation}${mobVal}${pageUrl}`;
    window.location.reload(true);

  };

  return(
    <div id="mobile-menu">
      <header>
        <div className="menu-but">
          <button onClick={() => props.setMenuState(!props.menuState)}><img src={ButMenu} alt=""/></button>
        </div>
        <div className="logo">
          <img src={LogoMobile} alt=""/>
        </div>
      </header>

      <div className={`menu ${props.menuState ? "on" : ""}`}>
        <div className="menu-header">
          <div className="menu-but">
            <button onClick={() => props.setMenuState(!props.menuState)}><img src={MenuClose} alt=""/></button>
          </div>
        </div>
        <div className="menu-content">
          {generateMenu(menu)}
        </div>
        {/*<div className="col location-menu">*/}
        {/*  <ul className="languages">*/}
        {/*    <li className="upper"  onClick={() => changeLocation("es")}>ESP</li>*/}
        {/*    <li className="upper"  onClick={() => changeLocation("en")}>ENG</li>*/}
        {/*    <li className="upper"  onClick={() => changeLocation("pt")}>POR</li>*/}
        {/*  </ul>*/}
        {/*  <ul>*/}
        {/*    <li className="upper" onClick={() => openLink()}>PDF</li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
