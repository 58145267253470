import React, { useState, useEffect } from 'react';


export default function Mobile(props) {

  return (
    <div id="mobile">

      <div className="content">
        {props.children}
      </div>
    </div>
  );
}
