import React from 'react';
import ButNext from '../../img/but-next.svg';
import ButPrev from '../../img/but-prev.svg';
import ButMenu from '../../img/but-menu.svg';
import ButHome from '../../img/but-home.svg';
import HeaderImg from '../../img/header.svg';
import HeaderEng from '../../img/header_en.svg';
import HeaderPor from '../../img/header-pt.svg';

export default function Navigation(props) {

  const [Header, setHeader ] = React.useState(HeaderImg);

  React.useEffect(() => {

    if(props.location === "en") {
      setHeader(HeaderEng);
    } else if(props.location === "pt") {
      setHeader(HeaderPor);
    } else {
      setHeader(HeaderImg);
    }

  }, props.location);

  return(
    <div id="navigation">
      <header>
        {props.currentPage !== 0 ? (
        <img src={Header} alt=""/>) : ''}
      </header>
      <div className="left-bar">
        <button className="naviMenu" onClick={() => window.location.hash = `/${props.location}/${props.device}/0-i0`}>
          <img src={ButHome} alt=""/>
        </button>
        <button className="naviMenu" onClick={() => props.setMenuState(true)}>
          <img src={ButMenu} alt=""/>
        </button>
      </div>
      <div className="arrows">
        <button className="arrow-left" onClick={() => props.prevPage()}><img src={ButPrev} alt=""/></button>
        <button className="arrow-right" onClick={() => props.nextPage()}><img src={ButNext} alt=""/></button>
      </div>
    </div>
  )
}
