import React, {useState, useEffect} from 'react';
import {MobileImage} from './MobileImage';
import uuid from 'uuid';

export  function Page(props) {

  const [pages, setPages] = useState([]);


  // fazer carregar multipla imagens
  // fazer um map loop e definir cada imagem que deu loading e fazer aparecer na tela

  useEffect(() => {
    if(props.files !== undefined) {
      setPages(props.files);
      //loadImage(props.file);

    }
  },[props.files]);

  const generateDFContent = () => {
    if(props.location === 'es') {
      return (
        <div key={uuid()} style={{background:'white', width:'100%', boxSizing:'border-box', padding:'2em', textAlign: 'left'}}>
          <a href="./dfs/EstadosFinancierosLATAM12-2019.pdf" target="_blank">
            <p>Descargar archivo PDF:<br/> Estados Financieros Diciembre 2019.pdf</p>
          </a>
        </div>
      )
    } else if(props.location === 'en') {
      return (
        <div key={uuid()} style={{background:'white', width:'100%', boxSizing:'border-box', padding:'2em', textAlign: 'left'}}>
          <a href="./dfs/Financial Statements - LATAM 2018.pdf" target="_blank">
            <p>Download PDF file:<br/> Financial Statements 2018.pdf</p>
          </a>
        </div>
      )
    } else if(props.location === 'pt') {
      return (
        <div key={uuid()} style={{background:'white', width:'100%', boxSizing:'border-box', padding:'2em', textAlign: 'left'}}>
          <a href="./dfs/Estados Financieros Diciembre 2018.pdf" target="_blank">
            <p>Baixar arquivo PDF:<br/> Estados Financieros Diciembre 2018.pdf</p>
          </a>
        </div>
      )
    }
  }


  const renderPageList = (list) => {
    return list.map((item) => {
      return (
        <>
          {console.log('render page lcoation', props.location)}
        <MobileImage  image={item} key={uuid()} alt="" location={props.location}/>
          {item === "latam-052.png" ? generateDFContent() :''}
        </>
        )
    });
  };

  return(
    <div className="page">
      {renderPageList(pages)}
      <div className="spacer"/>
    </div>
  )
}
