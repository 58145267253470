import React from 'react';
import ButNext from '../../img/but-next.svg';
import ButPrev from '../../img/but-prev.svg';

export function Navigation(props) {
  return !props.menuState ? (
    <div id="navigation">
      <div className="arrows">
        <div><button className="arrow-left" onClick={() => props.prevPage()}><img src={ButPrev} alt=""/></button></div>
        <div><button className="arrow-right" onClick={() => props.nextPage()}><img src={ButNext} alt=""/></button></div>
      </div>
    </div>
  ) : (<></>);
}
