import React, {useState, useEffect} from 'react';
import Loading from '../../img/loading.svg';

export function MobileImage (props) {

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);

  useEffect(() => {

    setLoading(true);
    if(props.image !== undefined) {
      loadImage(props.image)
    }
    return () => {
      setLoading(false);
    }
  }, []);

  const loadImage = (img) => {

    fetch(`./mobile/${props.location}/${img}`)
      .then(response => response.blob())
      .then((result) => {
        const url = URL.createObjectURL(result);
        setImage(url);
        setLoading(false);
      }, (error) => {
        console.log('deu erro na imagem');
        console.log(error);
      })
  };

  return loading ? (
    <div style={{width:'100%', boxSizing:'border-box', padding:'5em', display:'flex', height:'200px', textAlign:'center', AlignItems:'center', justifyContent:'center'}}>
      <img src={Loading} alt="" style={{width:'64px', height:'64px', alignSelf:'center'}}/>
    </div>
  ) : (
    <img src={image} alt=""/>
  )

};
