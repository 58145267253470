import React, {useState, useEffect} from 'react';
import MenuCloseBtn from './MenuCloseBtn';
import uuidv1 from 'uuid/v1';

import MenuClose from '../../img/but-menu-close.svg';

export default function Menu(props) {


  const [menu, setMenu] = useState([]);

  useEffect(()=> {
    if(props.structure !== undefined && props.structure.length > 0) {
      // console.log('structure from effect', props.structure);
      setMenu(props.structure);
    } else {
      // console.log('no structure yet')
    }
  },[props]);

  const generateMenu = (branch) => {
    return Array.isArray(branch) ?
      (<ul>{branch.map((item) => makeItem(item))}</ul>) :
      console.log('not array');
  };

  // window.location.hash = `/${props.location}/${props.device}/${branch.id + '-i0'}`

  const checkLink = branch => {

    if(branch.nolink === true) {
      return;
    }
    window.location.hash = `/${props.location}/${props.device}/${branch.id + '-i0'}`
  };

  const makeItem = (branch) => {
    return(
      <li key={uuidv1()} className={branch.titleType}>
        <span onClick={() => checkLink(branch)}>
          {branch.title[props.location]}
        </span>
        {branch.leaf? '': generateMenu(branch.children)}
      </li>
    );
  };

  const openLink = () => {
    console.log('open menu');

    if(props.location === "es") {
      window.open(
        './dfs/LATAM_MemoriaAnual2018_ESP.pdf',
        '_blank'
      );
    } else if (props.location === "en") {
      window.open(
        './dfs/LATAM_IntegratedReport_2018_EN.pdf',
        '_blank'
      );
    } else if (props.location === "pt") {
      window.open(
        './dfs/LATAM_RelatorioIntegrado2018_PT.pdf',
        '_blank'
      );
    }

  };

  const changeLocation = (newLocation) => {

    const actualPage = /\/(?:.(?!\/))+$/;
    const address = window.location.hash;
    const pageUrl = address.match(actualPage)[0];
    console.log(pageUrl);

    window.location.hash = `/${newLocation}/d${pageUrl}`;
    window.location.reload(true);

  };

  const makeMenuCols = (branch) => {
    return branch.length >= 7 ?
       (
        <React.Fragment>
          <div className="col1">{generateMenu(branch.slice(0,8))}</div>
          <div className="col2">{generateMenu(branch.slice(8))}</div>
          <div className="col location-menu">
            <ul className="languages">
              <li className="upper" onClick={() => changeLocation("es")}>ESP</li>
              <li className="upper" onClick={() => changeLocation("en")}>ENG</li>
              <li className="upper" onClick={() => changeLocation("pt")}>POR</li>
            </ul>
            <ul>
              <li className="upper" onClick={() => openLink()}>PDF</li>
            </ul>
          </div>
        </React.Fragment>
      ):(
        <div>{generateMenu(branch)}</div>
      )
  };

  return(
    <div className={`menu-wrapper ${props.menuState ? "on":""}`}>
      {makeMenuCols(menu)}
      <div className="menu-close-btn"><button onClick={() => props.setMenuState(!props.menuState)}>
        <img src={MenuClose} alt=""/>
      </button></div>
    </div>
  )
}
