import React, {useState, useEffect} from 'react';
import {MobileImage} from './MobileImage';

export  function Home(props) {


  const goToURL = (url) => {
    // console.log('scroll to', url);
    window.scrollTo(0, 0);
    window.location.hash = `/${props.location}/m/${url}`;
  };

  const renderPage = () =>{
      return (
        <div id="home">

            <MobileImage image="Latam19-00-c0.png" location={props.location} />

          <div onClick={() => goToURL('1')}>
            <MobileImage image="Latam19-00-c1.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('5-0')}>
            <MobileImage image="Latam19-00-c2.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('6-0')}>
            <MobileImage image="Latam19-00-c3.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('7-0')}>
            <MobileImage image="Latam19-00-c4.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('8-0-0')}>
            <MobileImage image="Latam19-00-c5.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('8-1-0')}>
            <MobileImage image="Latam19-00-c6.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('8-2-0')}>
            <MobileImage image="Latam19-00-c7.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('9-0-0')}>
            <MobileImage image="Latam19-00-c8.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('10-0-0')}>
            <MobileImage image="Latam19-00-c9.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('10-1-0')}>
            <MobileImage image="Latam19-00-c10.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('10-2-0')}>
            <MobileImage image="Latam19-00-c11.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('11-0')}>
            <MobileImage image="Latam19-00-c12.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('12-1')}>
            <MobileImage image="Latam19-00-c13.png"  location={props.location} />
          </div>
            <div onClick={() => goToURL('13-1')}>
                <MobileImage image="Latam19-00-c14.png"  location={props.location} />
            </div>
        </div>
      )

  };

  return(
    <div className="page">
      {renderPage()}
    </div>
  )
}
