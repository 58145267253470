import React from 'react';
import ButNext from '../../img/but-next.svg';
import ButMenu from '../../img/but-menu.svg';

export default function HomeNavigation(props) {


  const changeLocation = (newLocation) => {

    const actualPage = /\/(?:.(?!\/))+$/;
    const address = window.location.hash;
    const pageUrl = address.match(actualPage)[0];
    console.log('urllll', pageUrl);

    window.location.hash = `${newLocation}/d/0-i0`;
    window.location.reload(true);

  };

  return(
    <div id="navigation-home">

      <div className="left-bar">
        <button className="naviMenu" onClick={() => props.setMenuState(true)}>
          <img src={ButMenu} alt=""/>
        </button>
        <div className="location-menu">
          <span onClick={() => changeLocation('es')}>ESP</span> |
          <span onClick={() => changeLocation('en')}>ENG</span> |
          <span onClick={() => changeLocation('pt')}>POR</span>
        </div>
      </div>
      <div className="arrows home">
        <button className="arrow-right" onClick={() => props.nextPage()}><img src={ButNext} alt=""/></button>
      </div>
    </div>
  )
}
